import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { motion } from "framer-motion";

import "./slider.css";

const Slider = () => {
  const header = "CORVETTE SHIPPING MARINE CO.LTD".split(" ");
  const desc =
    "Experience Seamless Corvette Shipping Solutions with Our Comprehensive Services: Chartering, Ship Sales, Brokerage, Management, Classification, and Dry Dock Expertise.".split(
      " "
    );

  return (
    <>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 3500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide
          className="swiper-slide"
          style={{
            backgroundImage: "url(" + "/images/first-image.jpg" + ")",
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            width: "100%",
            height: "100%",
          }}
        ></SwiperSlide>
        <SwiperSlide
          className="swiper-slide"
          style={{
            backgroundImage: "url(" + "/images/second-img.jpg" + ")",
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            width: "100%",
            height: "100%",
          }}
        ></SwiperSlide>

        <SwiperSlide
          className="swiper-slide"
          style={{
            backgroundImage: "url(" + "images/third-img.jpg" + ")",
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",

            width: "100%",
            height: "100%",
          }}
        ></SwiperSlide>
        <div className="slideText" id="home">
          {header.map((el, i) => (
            <motion.span
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{
                duration: 0.25,
                delay: i / 10,
              }}
              key={i}
            >
              {el}{" "}
            </motion.span>
          ))}
          <br />
          {desc.map((el, i) => (
            <motion.span
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{
                duration: 0.25,
                delay: i / 10,
              }}
              key={i}
              className="descSpan"
            >
              {el}{" "}
            </motion.span>
          ))}
        </div>
      </Swiper>
    </>
  );
};

export default Slider;
