import React, { useRef } from "react";
import "./contact.css";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import FacebookIcon from "@mui/icons-material/Facebook";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { motion } from "framer-motion";

import emailjs from "@emailjs/browser";

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("service_wea6zg1", "template_8ymjg4m", form.current, {
        publicKey: "-MERN1CCRNhZHsjJb",
      })
      .then(
        () => {
          console.log("SUCCESS!");
          e.target.reset();
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );
  };

  return (
    <div className="contactContainer" id="contact">
      <h1>Contact Us</h1>

      <div className="contactInfoContainer">
        <motion.div
          className="contactLinks"
          initial={{ x: -150 }}
          whileInView={{ x: 0 }}
          transition={{ ease: "easeOut", duration: 1 }}
          viewport={{ once: true }}
        >
          <a href="mailto:shippingmarinecorvette@gmail.com" className="email">
            <EmailOutlinedIcon /> shippingmarinecorvette@gmail.com
          </a>
          <a href="tel:+306977161783" className="phone">
            <LocalPhoneOutlinedIcon /> +306977161783
          </a>
          <div className="socialLinks">
            <a
              href="https://wa.me/306977161783"
              target="_blank"
              className="whatsApp"
              rel="noreferrer"
            >
              <WhatsAppIcon
                style={{ width: "35px", height: "35px" }}
                className="icon"
              />
            </a>
            <a href="https://facebook.com" className="facebook">
              <FacebookIcon
                style={{ width: "35px", height: "35px" }}
                className="icon"
              />
            </a>
            <a href="https://linkedin.com" className="linkedIn">
              <LinkedInIcon
                style={{ width: "35px", height: "35px" }}
                className="icon"
              />
            </a>
          </div>
        </motion.div>
        <motion.div
          className="contactForm"
          initial={{ x: 150 }}
          whileInView={{ x: 0 }}
          transition={{ ease: "easeOut", duration: 1 }}
          viewport={{ once: true }}
        >
          <form ref={form} onSubmit={sendEmail}>
            <div className="labelInputContainer">
              <label htmlFor="">First Name: </label>
              <input
                type="text"
                name="firstName"
                placeholder="Enter Your First Name"
                required
              />
            </div>
            <div className="labelInputContainer">
              <label htmlFor="">Last Name: </label>
              <input
                type="text"
                name="lastName"
                placeholder="Enter Your Last Name"
                required
              />
            </div>
            <div className="labelInputContainer">
              <label htmlFor="">Email:</label>
              <input
                type="text"
                name="user_email"
                placeholder="Enter Your Email"
                required
              />
            </div>
            <div
              className="labelInputContainer"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <label htmlFor="textArea">Message:</label>
              <textarea
                name="message"
                cols="30"
                rows="10"
                style={{ outline: "none", padding: "10px 12px" }}
                required
                className="textarea"
              ></textarea>
            </div>
            <button type="submit">SEND</button>
          </form>
        </motion.div>
      </div>
    </div>
  );
};

export default Contact;
