import React, { useRef, useState } from "react";
import "./navbar.css";
import { useClickAway } from "react-use";
import { AnimatePresence, motion } from "framer-motion";
import { Squash as Hamburger } from "hamburger-react";
import {
  HandshakeOutlined,
  HomeOutlined,
  InfoOutlined,
  PhoneOutlined,
} from "@mui/icons-material";

const Navbar = () => {
  const [colorChange, setColorchange] = useState(false);

  const changeNavbarColor = () => {
    if (window.scrollY >= 80) {
      setColorchange(true);
    } else {
      setColorchange(false);
    }
  };
  window.addEventListener("scroll", changeNavbarColor);

  const routes = [
    {
      title: "Home",
      href: "#home",
      Icon: HomeOutlined,
    },
    {
      title: "About Us",
      href: "#about",
      Icon: InfoOutlined,
    },
    {
      title: "Services",
      href: "#services",
      Icon: HandshakeOutlined,
    },
    {
      title: "Contact Us",
      href: "#contact",
      Icon: PhoneOutlined,
    },
  ];
  const [isOpen, setOpen] = useState(false);
  const ref = useRef(null);

  useClickAway(ref, () => setOpen(false));

  return (
    <div
      className={
        colorChange ? "navbarContainer colorChange" : "navbarContainer"
      }
    >
      <div className="logo">
        <a href="#home">
          <img src="/images/logo3.png" alt="CORVETTE." />
        </a>
      </div>

      <div className="navbarLinksContainer">
        {/* //////////////////// */}
        <div ref={ref} className="md:hidden" style={{ marginLeft: "80%" }}>
          <Hamburger toggled={isOpen} size={20} toggle={setOpen} />
          <AnimatePresence>
            {isOpen && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2 }}
                className="fixed mt-4 h-full left-0 shadow-4xl right-0 top-[3.5rem] p-5 pt-0 bg-neutral-950 border-b border-b-white/20"
              >
                <ul className="flex flex-col gap-2 second-ul">
                  {routes.map((route, idx) => {
                    const { Icon } = route;

                    return (
                      <motion.li
                        initial={{ scale: 0, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        transition={{
                          type: "spring",
                          stiffness: 260,
                          damping: 20,
                          delay: 0.1 + idx / 10,
                        }}
                        key={route.title}
                        className="w-full p-[0.08rem] rounded-xl bg-gradient-to-tr from-neutral-800 via-neutral-950 to-neutral-700"
                      >
                        <a
                          onClick={() => setOpen((prev) => !prev)}
                          className={
                            "flex items-center justify-between w-full p-5 rounded-xl bg-neutral-950"
                          }
                          href={route.href}
                        >
                          <span className="flex gap-1 text-lg">
                            {route.title}
                          </span>
                          <Icon className="text-xl" />
                        </a>
                      </motion.li>
                    );
                  })}
                </ul>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
        {/* //////////////////////////////////////////// */}
        <ul className="firstList">
          <li>
            <a href="#home">Home</a>
          </li>
          <li>
            <a href="#about">About Us</a>
          </li>
          <li>
            <a href="#services">Services</a>
          </li>
          <li>
            <a href="#contact">Contact Us</a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
