import React from "react";
import "./app.css";
import Navbar from "./Navbar/Navbar";
import Slider from "./Slider/Slider";
import AboutUs from "./About us/AboutUs";
import Services from "./Services/Services";
import Contact from "./Contact/Contact";
import Footer from "./Footer/Footer";

const App = () => {
  return (
    <div className="app">
      <Navbar />
      <Slider />
      <AboutUs />
      <Services />
      <Contact />
      <Footer />
    </div>
  );
};

export default App;
