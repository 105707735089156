import React from "react";
import "./aboutus.css";
const AboutUs = () => {
  return (
    <div className="aboutUsContainer" id="about">
      <h1>About Us</h1>
      <span></span>
      <div className="aboutUsContent">
        <img src="/images/about.jpg" alt="about the company" />
        <p>
          Corvette Shipping Ltd. was founded in Greece. Since its establishment,
          it has provided maritime transportation services on behalf of its
          clients by managing dozens of ships of various types and sizes. The
          company is able to provide a range of ship management and maritime
          transportation services to its clients, including daily operational
          functions. Corvette currently operates a modern fleet of tankers, all
          of which are Greek flagged and classified with the major IACS
          Classification Societies as well as several other flags." As was our
          purposeful motto, it is based on the fact that quality is the root of
          stability and continuity ". And as Corvette Shipping & Logistics Co.,
          Ltd. allows you. A comprehensive range of flexible freight services to
          meet the ever-changing requirements for transporting goods. Our sea
          freight services cover many major ports around the world. Whether you
          are looking for port to door transportation service, our team is well
          experienced to help you overcome the complexity of freight
          transportation and can provide effective solutions to suit your
          transportation requirements. Our partnerships with reputable carriers
          allow us to offer multiple sailing times at competitive rates, and
          with our real-time shipment tracking system, we ensure your shipment
          gets where it needs to be smoothly and quickly. We hope that we have
          provided a comprehensive review of our shipping agency, and we will be
          very happy to provide our best services to your ships calling at all
          global ports and we look forward to establishing a business
          relationship with your esteemed companies.
        </p>
      </div>
    </div>
  );
};

export default AboutUs;
