import React from "react";
import "./footer.css";

const Footer = () => {
  return (
    <div className="footerContainer">
      <h1>CORVETTE SHIPPING MARINE CO.LTD</h1>
      <h4>GENERAL MANAGER: MR. TAREK DEEB</h4>
    </div>
  );
};

export default Footer;
