import React from "react";
import "./services.css";
import Popup from "reactjs-popup";
import CloseIcon from "@mui/icons-material/Close";
import { motion } from "framer-motion";

const Services = () => {
  const servicesData = [
    {
      id: 1,
      title: "Chartering",
      description:
        "Our company benefits from charter vessels by maintaining a....",
      fullDescription:
        "Our company benefits from charter vessels by maintaining a good reputation in the dry shipping market.  We ship thousands of tons of bulk and other cargo, all over the world using our managed fleet, exclusive tonnage and other vessels chartered from the market.",
      img: "/images/chartering.jpg",
    },
    {
      id: 2,
      title: "Ship For Sale And Brushes",
      description:
        "Corvette Marine Shipping Company Limited gives you the greatest opportunity to....",
      fullDescription:
        "Corvette Marine Shipping Company Limited gives you the greatest opportunity to select your vessel with the latest capabilities in the global market. It also helps you by providing marketing visions and solutions to sell or buy your vessel within the required specifications and at the best competitive prices for the global market. It also provides dynamic and technical inspection services on ships. Before selling or buying it to avoid any harm that may befall both parties, it also confirms its slogan with its business approach, which aims to ensure that quality is the basis of stability and continuity.",
      img: "/images/shipForsale.jpg",
    },
    {
      id: 3,
      title: "Ship Broker",
      description:
        "Corvette Shipping Marine Co Ltd plans, implements and controls the movement and....",
      fullDescription:
        "Corvette Shipping Marine Co Ltd plans, implements and controls the movement and storage of goods, services or information within the supply chain and between points of origin and consumption. Different logistics companies handle some or all of these supply chain functions, depending on the customer's logistical needs, to secure the goods and ensure they are shipped and arrive at delivery ports in a proper manner that does not cause confusion for all parties.",
      img: "/images/shipBroker.jpg",
    },
    {
      id: 4,
      title: "Ship Management",
      description:
        "Corvette Shipping Marine Co Ltd manages ships by exerting its best efforts to ....",
      fullDescription: `Corvette Shipping Marine Co Ltd manages ships by exerting its best efforts to provide the best management services agents on behalf of owners in accordance with sound ship management practices and to protect and promote the interests of owners in all matters relating to the provision of necessary services... Corvette Shipping Marine Co Ltd also provides ship management with many technical management services, including: 
      1. Ensure that all ships comply with the laws of the country whose flag they fly. 
      2. Ensure that the regulations and requirements of the ISM Safety Management Code are followed.
      3. Ensure that the regulations of the International Code for the Security of Ships and Port Facilities (ISPS) are followed. 4. Providing specialized personnel to supervise maintenance operations and general efficiency of ships.
       Adding Corvette Shipping Marine Co Ltd to ship management, the fleet is ready for operation by following and managing the following applications:
      1. Value preservation, life cycle management, online monitoring of technical parameters, and efficient vessel operation.
      2. Technical inspections, condition assessment surveys, procurement, and door-to-deck logistics.
      3. Maintenance and energy efficiency management.
      4. Dry docking, repair, development and modification projects.
      5. Planned/periodic maintenance, condition monitoring and preparation of regular technical condition reports.
      6. Budget preparation, commitment and green ship recycling/hazardous materials inventory.
      7. Technical services and consultations, satellite repair and conversion, and integrated IT solutions.
      It also provides ship management with a qualified crew that complies with the standards of the International Convention on Standards of Training, Certification and Watchkeeping for Seafarers (STCW).
      1. By selecting, engaging and providing crew management as needed, salary arrangements, pension arrangements, taxes, social security contributions and other mandatory entitlements related to their work, which are paid to each crew member at their home country.
      2. Ensure that the requirements stipulated by the law of the ship's flag state regarding rank, qualifications, crew documents and employment regulations are met, such as crew tax and social insurance.
      `,
      img: "/images/shipManagement.jpg",
    },
    {
      id: 5,
      title: "Marine Classification",
      description:
        "corvette shipping marine co ltd Through its partners in the marine classification bodies for....",
      fullDescription:
        "corvette shipping marine co ltd Through its partners in the marine classification bodies for working ships, it sets standards for the design and construction of ships, and maintains these standards throughout the life of the ship through investigation, in order to ensure that high technical standards are met in design, manufacture, construction, maintenance, operation and performance, for the benefit of society and in order to enhance life safety.  And property at sea, and it also provides the client with the experience of granting flags, registering ships, and other modern means of communication in the field of supporting and ensuring the preservation of the safety of ships operating at sea through diligent and continuous follow-up within joint work protocols between it and its partners from the American, British, French, and German classification bodies.  Italian, Norwegian, Japanese, Russian, Chinese and South Korean, in addition to observer members from the Croatian and Indian classification bodies. ",
      img: "/images/marine.jpg",
    },
    {
      id: 6,
      title: "Dry Dock Service",
      description:
        "First port of call for dry docking Built on a secure stability architecture....",
      fullDescription:
        "First port of call for dry docking Built on a secure stability architecture, the dry docking system allows you to integrate the entire maintenance and procurement process, making the management of all aspects of your technical projects simple and hassle-free. Assist technical teams in planning, sourcing, implementation and reporting on all projects, all of which are available within our office services on the newest and best dry docks and docks in the world. Complete optimization Our solutions can pull data from other applications associated with Corvette Services dry docking services, to get a complete view of each project, from concept to completion. We also provide you with the opportunity to book an appointment in advance for safe berthing in the dry dock by ensuring all technical requirements based on what is needed for the ship during the complete Umrah process and with a standard maintenance time competitive with other markets. ",

      img: "/images/dry.jpg",
    },
  ];

  return (
    <div className="servicesContainer" id="services">
      <h1>Our Services</h1>
      <span></span>
      <div className="servicesCards">
        {servicesData.map((item) => (
          <motion.div
            initial={{ scale: 0 }}
            whileInView={{ scale: 1 }}
            transition={{
              type: "spring",
              stiffness: 150,
              damping: 25,
            }}
            viewport={{ once: true }}
            key={item.id}
            className="serviceCard"
          >
            <img src={item.img} alt="Shipping Eforwarding agency" />
            <h2>{item.title}</h2>
            <p>{item.description}</p>
            {/* <div className="learnMore">Learn more...</div> */}
            <Popup
              trigger={<button className="learnMore">Learn more...</button>}
              modal
              nested
            >
              {(close) => (
                <div className="modal">
                  <div className="modalContainer">
                    <div>
                      <button className="closeIcon" onClick={() => close()}>
                        <CloseIcon />
                      </button>
                    </div>
                    <div
                      className="content"
                      style={{
                        whiteSpace: "pre-line",
                        wordBreak: "break-word",
                        overflowWrap: "break-word",
                      }}
                    >
                      {item.fullDescription}
                    </div>
                  </div>
                </div>
              )}
            </Popup>
            <a href="#contact">Contact</a>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default Services;
